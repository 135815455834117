import React from 'react';

import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import Container from '../components/container/container';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NotFoundPage = ({ location }) => {
  const langCode =
    location.pathname.split('/').filter(e => e !== '')[0] === 'en'
      ? 'en'
      : 'nl';
  return (
    <Layout location={location}>
      <SEO title="Pagina niet gevonden" lang={langCode} />
      <div className="paragraph__page-header gecentreerd">
        <Container>
          <h1>Pagina niet gevonden</h1>
          <ul className="breadcrumbs">
            <div className="breadcrumb">
              <li>
                <Link to={'/'}>Home</Link>
              </li>
              <li className="arrow">
                <FontAwesomeIcon icon={['fa', 'long-arrow-alt-right']} />
              </li>
            </div>
            <li>Pagina niet gevonden</li>
          </ul>
          <div className="text">
            <p>De pagina die je zocht bestaat helaas niet (meer).</p>
          </div>
          <div className="cta">
            <div className="cta__item">
              <Link to={'/'} className={'btn secondary big'}>
                Ga terug naar home
              </Link>
            </div>
          </div>
        </Container>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
